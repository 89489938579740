import React from 'react';

export default class Notes extends React.Component {
    render() {
        return (
            <div className="text-muted pt-3" style={{ fontSize: '0.875rem', fontWeight: 500 }}>
                Love you 3000 ♥♥♥!
            </div>
        )
    }
}